import React, { useRef } from 'react';
import './App.css';

function App() {
  const divRef = useRef(null);

  const handleClick = (e) => {
    divRef.current.scrollTo(0, 800);
  };

  return (
    <div className="container" ref={divRef}>
      <header className="container-header">
        <img src={require("./assets/head-img.png")} mode="widthFix" alt='' />
        <div className='head-position'>
          <img src={require("./assets/logo.png")} mode="widthFix" alt='' />
          <div>
            <div>首页</div>
            <div onClick={handleClick}>关于我们</div>
          </div>
          <img src={require("./assets/search.png")} mode="widthFix" alt='' />
        </div>
      </header>
      <section>
        <div className='title'>
          <img src={require("./assets/title-icon.png")} mode="widthFix" alt='' />
          <div>关于我们</div>
          <div>About us</div>
        </div>

        <div className='sect-aboutUs'>
          <img src={require("./assets/fruit.png")} mode="widthFix" alt='' />
          <div>
            <div>简介</div>
            <div>本公司专注于生鲜行业的供应链管理和销售，致力于为消费者提供新鲜、安全、健康的农产品。我们从农户或合作社采购优质农产品，经过严格的质量检验和初步加工，确保产品的新鲜度和食品安全。我们拥有成熟的物流和仓储体系，确保产品在运输和存储过程中保持最佳状态。同时，我们与农户和合作社建立紧密的合作关系，共同推动农业产业的可持续发展，确保农产品的供应稳定和质量可控。在销售方面，我们以多元化的销售渠道满足不同消费者的需求。除了传统的农贸市场和超市外，我们还积极拓展电商平台，利用互联网技术为消费者提供更加便捷、快速的购物体验。我们注重产品的可追溯性和可持续性，通过建立农产品追溯系统，让消费者能够了解产品的来源和生产过程。我们推广有机农业和绿色农业的理念，减少农业生产对环境的影响，为地球的可持续发展贡献力量。在未来的发展中，我们将继续秉持“新鲜、安全、健康”的经营理念，不断创新和完善产品和服务，为消费者提供更加优质的购物体验。同时，我们将携手合作伙伴和农户，共同推动生鲜行业的可持续发展。</div>
          </div>
        </div>

        <div className='sect-info'>
          <div>Copyright©2023-2033. All Rights Reserved.  |  浙ICP备2024058425号-1</div>
          <div>本网站由劲恒（杭州）电子商务有限公司提供服务</div>
        </div>
      </section>
    </div>
  );
}

export default App;
